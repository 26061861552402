import React, { createContext, useContext, useReducer } from 'react';

const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM': {
      const { 
        id,
        name,
        price = 0,
        size,
        photo,
        quantity = 1,
        supplements = [],
        supplementPrice = 0 
      } = action.payload;
      
      // Créer un identifiant unique pour cette pizza avec ses suppléments
      const itemId = `${id}-${supplements.join('')}`;
      
      const existingItem = state.items.find(i => 
        i.id === itemId &&
        i.size === size
      );
      
      const newItem = {
        id: itemId,
        name,
        price,
        size,
        photo,
        quantity,
        supplements,
        supplementPrice,
        totalPrice: (Number(price) + Number(supplementPrice)) * quantity
      };
      
      if (existingItem) {
        return {
          ...state,
          items: state.items.map(i =>
            i.id === itemId
              ? { 
                  ...i, 
                  quantity: i.quantity + quantity,
                  totalPrice: (Number(i.price) + Number(i.supplementPrice)) * (i.quantity + quantity)
                }
              : i
          ),
          totalItems: state.items.reduce((total, item) => total + item.quantity, 0) + quantity,
          lastAddedItem: itemId
        };
      }
      
      return {
        ...state,
        items: [...state.items, newItem],
        totalItems: state.items.reduce((total, item) => total + item.quantity, 0) + quantity,
        lastAddedItem: itemId
      };
    }

    case 'UPDATE_QUANTITY':
      return {
        ...state,
        items: state.items.map(item =>
          item.id === action.payload.id
            ? { ...item, quantity: action.payload.quantity }
            : item
        ),
        totalItems: state.items.reduce((total, item) => total + item.quantity, 0)
      };

    case 'REMOVE_ITEM': {
      const removedItem = state.items.find(item => item.id === action.payload);
      if (!removedItem) return state;
      
      const newItems = state.items.filter(item => item.id !== action.payload);
      
      return {
        ...state,
        items: newItems,
        totalItems: newItems.reduce((total, item) => total + item.quantity, 0),
        isCartOpen: newItems.length > 0 ? state.isCartOpen : false
      };
    }

    case 'TOGGLE_CART':
      return {
        ...state,
        isCartOpen: !state.isCartOpen
      };

    case 'RESET_LAST_ADDED':
      return {
        ...state,
        lastAddedItem: null
      };

    default:
      return state;
  }
};

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, {
    items: [],
    totalItems: 0,
    isCartOpen: false,
    lastAddedItem: null
  });

  const addItem = (item) => {
    dispatch({ type: 'ADD_ITEM', payload: item });
  };

  const updateQuantity = (id, quantity) => {
    dispatch({ type: 'UPDATE_QUANTITY', payload: { id, quantity } });
  };

  const removeFromCart = (id) => {
    dispatch({ type: 'REMOVE_ITEM', payload: id });
  };

  const toggleCart = () => {
    dispatch({ type: 'TOGGLE_CART' });
    
    if (!state.isCartOpen) {
      setTimeout(() => {
        dispatch({ type: 'RESET_LAST_ADDED' });
      }, 300);
    }
  };

  return (
    <CartContext.Provider
      value={{
        items: state.items,
        totalItems: state.totalItems,
        isCartOpen: state.isCartOpen,
        lastAddedItem: state.lastAddedItem,
        addItem,
        updateQuantity,
        removeFromCart,
        toggleCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

export { CartProvider, useCart };
