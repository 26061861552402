import margherita from '../assets/img/margherita.jpg'
import quattroFromaggi from '../assets/img/4-fromages.jpg'
import bolognaise from '../assets/img/bolognaise.jpg'
import chorizo from '../assets/img/chorizo.jpg'
import cipolla from '../assets/img/cipolla.jpg'
import napoli from '../assets/img/napoli.jpg'
import hawai from '../assets/img/hawai.jpg'
import barbecue from '../assets/img/barbecue.jpg'
import tartiflette from '../assets/img/tartiflette.jpg'
import calzone from '../assets/img/calzone.jpg'

// Liste des pizzas disponibles

export const pizzaList = [
  {
    name: 'Margherita',
    id: '1',
    ingredient: ['Tomate', 'Mozzarella', 'Basilic'],
    allergenes: ['Lait'],
    photo: margherita,
    prices: {
      classique: 10,
      large: 13
    }
  },

  {
    name: '4 Fromages',
    id: '10',
    ingredient: ['Tomate', 'Mozzarella', 'Cheddar', 'Comté', 'Gorgonzola'],
    allergenes: ['Lait'],
    photo: quattroFromaggi,
    prices: {
      classique: 10.50,
      large: 13.50
    }
  },

  {
    name: 'bolognaise',
    id: '2',
    ingredient: ['tomate', 'haché de boeuf', 'mozza'],
    allergenes: ['Lait'],
    photo: bolognaise,
    prices: {
      classique: 11.50,
      large: 13.50
    }
  },

  {
    name: 'chorizo',
    id: '3',
    ingredient: ['tomate', 'mozzarella', 'salami piquant'],
    allergenes: ['Lait'],
    photo: chorizo,
    prices: {
      classique: 11,
      large: 13.50
    }
  },
  {
    name: 'cipolla',
    id: '4',
    ingredient: ['tomate', 'mozzarella', 'oignons'],
    allergenes: ['Lait'],
    photo: cipolla,
    prices: {
      classique: 10.50,
      large: 12.50
    }
  },
  {
    name: 'napoli',
    id: '5',
    ingredient: ['tomate', 'mozzarella', 'anchois', 'câpres'],
    allergenes: ['Lait', 'Poisson'],
    photo: napoli,
    prices: {
      classique: 11,
      large: 13
    }
  },

  {
    name: 'hawai',
    id: '6',
    ingredient: ['tomate', 'mozzarella', 'jambon', 'ananas'],
    allergenes: ['Lait'],
    photo: hawai,
    prices: {
      classique: 11.50,
      large: 13.50
    }
  },
  {
    name: 'barbecue',
    id: '7',
    ingredient: ['tomate', 'mozzarella', 'oignons', 'lardons', 'sauce barbecue'],
    allergenes: ['Lait'],
    photo: barbecue,
    prices: {
      classique: 12,
      large: 15
    }
  },
  {
    name: 'tartiflette',
    id: '8',
    ingredient: ['reblochon', 'pommes de terre', 'lardons', 'oignons'],
    allergenes: ['Lait'],
    photo: tartiflette,
    prices: {
      classique: 13,
      large: 16
    }
  },

  {
    name: 'calzone',
    id: '9',
    ingredient: ['tomate', 'mozzarella', 'jambon', 'champignons'],
    allergenes: ['Lait'],
    photo: calzone,
    prices: {
      classique: 12,
      large: 15
    }
  }
]

export const supplementList = [
  {
    id: '1',
    supplement: 'fromage',
    price: 1.2
  },
  {
    id: '2',
    supplement: 'mozzarela',
    price: 1.2
  },
  {
    id: '3',
    supplement: 'jambon',
    price: 1.5
  },
  {
    id: '4',
    supplement: 'olive',
    price: 1
  },
  {
    id: '5',
    supplement: 'champignon',
    price: 1
  },
  {
    id: '6',
    supplement: 'mergez',
    price: 2
  }
]
