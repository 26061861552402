import React from 'react';
import '../styles/histoire.css';

const Histoire = () => {
  return (
    <div className="histoire-container">
      <h2>Notre Histoire</h2>
      <div className="histoire-content">
        <img src={require("../assets/img/histoire-meraviglioso.png")} alt="Restaurant" />
        <div className="histoire-text">
          <p>
            Fondée en 1998 par la famille Van Wymeersch, notre pizzeria est devenue une institution
            dans la région. Nous nous engageons à utiliser des ingrédients frais et locaux pour
            créer des pizzas authentiques.
          </p>
          <p>
            Notre équipe de pizzaiolos expérimentés prépare chaque pizza avec passion et savoir-faire,
            en respectant les traditions italiennes tout en innovant avec de nouvelles saveurs.
          </p>
        </div>
      </div>
    </div>
  );
};

export { Histoire };
