import React, { useState, useEffect } from "react";
import { garniture } from "../datas/garniture";
import { Modal } from "./Modal";
import "../styles/type-pizza.css";
import { FaInfoCircle } from "react-icons/fa";

function TypePizza({ pizza, selectedSize, onAddToCart }) {
  const [showModal, setShowModal] = useState(false);
  const [quantity, setQuantity] = useState(() => {
    const storedQuantity = localStorage.getItem(`pizza_${pizza.id}_quantity`);
    return storedQuantity ? parseInt(storedQuantity) : 0;
  });
  useEffect(() => {
    localStorage.setItem(`pizza_${pizza.id}_quantity`, quantity);
  }, [quantity, pizza.id]);

  const [selectedSupplements, setSelectedSupplements] = useState([]);

  const handleAddToCart = () => {
    const supplements = garniture
      .filter((ing) => selectedSupplements.includes(ing.id))
      .map((ing) => ing.name);

    const supplementPrice = garniture
      .filter((ing) => selectedSupplements.includes(ing.id))
      .reduce((sum, ing) => sum + ing.price, 0);

    const item = {
      id: `${pizza.id}-${Date.now()}`,
      name: pizza.name,
      price: parseFloat(pizza.prices[selectedSize]),
      size: selectedSize === "classique" ? "Classique" : "Large",
      photo: pizza.photo,
      quantity: quantity,
      supplements: garniture
        .filter((ing) => selectedSupplements.includes(ing.id))
        .map((ing) => ing.name),
      supplementPrice: supplementPrice,
    };
    onAddToCart(item);
  };

  const handleCardClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="type-pizza">
        <img className="type-pizza__image" src={pizza.photo} alt={pizza.name} />
        <div className="type-pizza__content">
          <h3>
            {pizza.name}
            <span className="info-icon" title={`Allergènes: ${pizza.allergenes.join(', ')}`}>
              <FaInfoCircle />
            </span>
            <span className="price">{pizza.prices[selectedSize]}€</span>
          </h3>
          <div className="pizza-type">
            <span>{selectedSize === "classique" ? "Classique" : "Large"}</span>
            <span style={{marginLeft: '8px'}}>{pizza.prices[selectedSize]}€</span>
          </div>
          <p>
            {pizza.ingredient.map((ing, index) => (
              <span key={index}>{ing}</span>
            ))}
          </p>
        </div>
        <div className="pizza-actions">
          <button className="add-to-cart-btn" onClick={handleCardClick}>
            +
          </button>
        </div>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Choisissez vos suppléments</h2>
            <Modal
              hide={handleCloseModal}
              pizza={pizza}
              addToPanier={onAddToCart}
              selectedSize={selectedSize}
              basePrice={pizza.prices[selectedSize]}
            />
            <div className="supplements-list">
              {garniture.map((ingredient) => (
                <div key={ingredient.id} className="supplement-item">
                  <label>
                    <input
                      type="checkbox"
                      name="supplement"
                      value={ingredient.id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedSupplements((prev) => [
                            ...prev,
                            ingredient.id,
                          ]);
                        } else {
                          setSelectedSupplements((prev) =>
                            prev.filter((id) => id !== ingredient.id)
                          );
                        }
                      }}
                    />
                    {ingredient.name} (+{ingredient.price}€)
                  </label>
                </div>
              ))}
            </div>
            <div className="modal-actions">
              <button
                className="add-to-cart-modal-btn"
                onClick={() => {
                  handleAddToCart();
                  handleCloseModal();
                }}
              >
                Ajouter au panier
              </button>
              <button className="close-modal" onClick={handleCloseModal}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export { TypePizza };
