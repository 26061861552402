import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import '../styles/header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { totalItems, toggleCart } = useCart();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="header-content">
      <div className="logo">
        <img src={require("../assets/img/meraviglioso-logo.png")} alt="Meraviglioso logo" />
      </div>

        <div className="hamburger" onClick={toggleMenu}>
          <svg viewBox="0 0 100 80" width="30" height="30">
            <rect width="100" height="10"></rect>
            <rect y="30" width="100" height="10"></rect>
            <rect y="60" width="100" height="10"></rect>
          </svg>
        </div>

        <nav className={`main-nav ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
          <ul>
            <li>
              <NavLink exact to="/" activeClassName="active">
                Accueil
              </NavLink>
            </li>
            <li>
              <NavLink to="/histoire" activeClassName="active">
                Notre Histoire
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" activeClassName="active">
                À Propos
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" activeClassName="active">
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className="cart-icon" onClick={(e) => {
          e.stopPropagation();
          toggleCart();
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/>
          </svg>
          <span className="cart-count">{totalItems || 0}</span>
        </div>
      </div>
    </header>
  );
}

export { Header };
