import React from 'react';
import '../styles/about.css';

const About = () => {
  return (
    <div className="about-container">
      <h1 className="about-title">À propos de Meraviglioso</h1>
      
      <div className="about-content">
        <section className="about-section">
          <h2>Notre histoire</h2>
          <p>
            Depuis 1998, Meraviglioso vous propose des pizzas artisanales préparées avec passion.
            Notre savoir-faire italien allié à des produits frais et locaux fait de nous une référence
            dans la région.
          </p>
        </section>

        <section className="about-section">
          <h2>Notre philosophie</h2>
          <p>
            Chez Meraviglioso, nous croyons que la qualité commence par le choix des ingrédients.
            C'est pourquoi nous sélectionnons avec soin nos produits et préparons chaque pizza
            avec amour et attention.
          </p>
        </section>

        <section className="about-section">
          <h2>Notre équipe</h2>
          <p>
            Une équipe passionnée de pizzaiolos et de serveurs dévoués travaille chaque jour
            pour vous offrir une expérience unique. Venez découvrir notre savoir-faire !
          </p>
        </section>
      </div>
    </div>
  );
};

export { About };
