import React, { useState } from "react";
import ReactDOM from "react-dom";
import { garniture } from "../datas/garniture";
import "../styles/modal.css";

export const Modal = ({
  hide,
  pizza = {},
  addToPanier,
  selectedSize,
  basePrice = 0,
}) => {
  // Vérification et valeurs par défaut
  const { name = "", ingredient = "" } = pizza;
  const [showAllIngredients, setShowAllIngredients] = useState(false);

  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [supplementPrice, setSupplementPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      hide(false);
    }
  };

  return ReactDOM.createPortal(
    <div className="cde-fond-ecran" onClick={handleClose}>
      <div className="cde-content">
        <button
          className="btn bleu"
          onClick={() => hide(false)}
          aria-label="Fermer la modale"
        >
          X
        </button>

        <div className="cde-title">
          {name}{" "}
          <span>{selectedSize === "classique" ? "Classique" : "Large"}</span>
        </div>


        <div className="selected-supplements">
          <h4>Détail du prix :</h4>
          <p>Prix de base : {parseFloat(basePrice).toFixed(2)}€</p>
          {supplementPrice > 0 && (
            <p>Suppléments : +{supplementPrice.toFixed(2)}€</p>
          )}
          <p className="total-price">
            Total : {(parseFloat(basePrice) + supplementPrice).toFixed(2)}€
          </p>
        </div>
        
        <div className="composition-section">
          <h3>Composition de la pizza</h3>
          <div className={`cde-ingredient ${showAllIngredients ? 'show-all' : ''}`}>
            {pizza.ingredient && Array.isArray(pizza.ingredient)
              ? <ul>
                  {pizza.ingredient.map((ing, index) => (
                    <li key={index}>{ing}</li>
                  ))}
                </ul>
              : <p>Aucune composition disponible</p>}
          </div>
        </div>
        
        {Array.isArray(ingredient) && ingredient.length > 5 && (
          <button 
            className="btn vert"
            onClick={() => setShowAllIngredients(!showAllIngredients)}
            style={{margin: '10px auto', display: 'block'}}
          >
            {showAllIngredients ? 'Voir moins' : 'Voir plus'}
          </button>
        )}

        <div className="cde-body">
          <div className="supplements-section">
            <h3>Supplements disponibles</h3>
            <div className="supplements-list">
              {garniture.map((ingredient) => (
                <div key={ingredient.id} className="supplement-item">
                  <label>
                    <input
                      type="checkbox"
                      name="supplement"
                      value={ingredient.id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedSupplements((prev) => [
                            ...prev,
                            ingredient.id,
                          ]);
                          setSupplementPrice((prev) => prev + ingredient.price);
                          setTotalPrice((prev) => prev + ingredient.price);
                        } else {
                          setSelectedSupplements((prev) =>
                            prev.filter((id) => id !== ingredient.id)
                          );
                          setSupplementPrice((prev) => prev - ingredient.price);
                          setTotalPrice((prev) => prev - ingredient.price);
                        }
                      }}
                    />
                    {ingredient.name} (+{ingredient.price.toFixed(2)}€)
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="cde-footer">
          <div className="panier-info">
            <button
              className="btn vert"
              onClick={() => {
                addToPanier({
                  id: `${name
                    .toLowerCase()
                    .replace(/\s+/g, "-")}-${Date.now()}`,
                  name,
                  price: parseFloat(basePrice) + supplementPrice,
                  size: selectedSize === "classique" ? "Classique" : "Large",
                  supplements: garniture
                    .filter((ing) => selectedSupplements.includes(ing.id))
                    .map((ing) => ing.name),
                  supplementPrice: supplementPrice,
                });
                hide(false);
              }}
            >
              Ajouter au panier
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
