import React from "react";
import { useCart } from "../context/CartContext.jsx";
import { garniture } from "../datas/garniture";
import "../styles/panier.css";

function Panier() {
  const { 
    items: cartItems = [], 
    totalItems, 
    isCartOpen, 
    toggleCart,
    removeFromCart,
    updateQuantity
  } = useCart();

  const handleQuantityChange = (item, amount) => {
    const newQuantity = item.quantity + amount;
    if (newQuantity > 0) {
      updateQuantity(item.id, newQuantity);
    } else {
      removeFromCart(item.id);
    }
  };

  const calculateItemTotal = (item) => {
    const basePrice = item.price || 0;
    const supplementsPrice = item.supplements?.reduce((sum, supp) => {
      const suppPrice = garniture.find(g => g.name === supp)?.price || 0;
      return sum + suppPrice;
    }, 0) || 0;
    
    return (basePrice + supplementsPrice) * item.quantity;
  };

  const totalPrice = cartItems.reduce(
    (total, item) => total + calculateItemTotal(item),
    0
  );

  return (
    <>
      <div className={`panier-overlay ${isCartOpen ? 'visible' : ''}`} onClick={toggleCart}>
        <div className={`panier-container ${isCartOpen ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
          <div className="panier-content">
            <div className="panier-header">
              <h2>Votre Panier ({totalItems} articles)</h2>
              <button className="close-btn" onClick={toggleCart}>
                &times;
              </button>
            </div>
            
            <div className="panier-items">
              {cartItems.length === 0 ? (
                <div className="empty-cart">Votre panier est vide</div>
              ) : (
                <>
                  {cartItems.map((item) => {
                    const itemTotal = calculateItemTotal(item);
                    const basePrice = item.price || 0;
                    const supplementsPrice = item.supplements?.reduce((sum, supp) => {
                      const suppPrice = garniture.find(g => g.name === supp)?.price || 0;
                      return sum + suppPrice;
                    }, 0) || 0;

                    return (
                      <div key={`${item.id}-${item.size}`} className="cart-item">                     
                        <div className="cart-item-info">
                          <h3>{item.name}</h3>
                          <p>Taille: {item.size}</p>
                          <p>Prix de base: {basePrice.toFixed(2)}€</p>
                          
                          {item.supplements && item.supplements.length > 0 && (
                            <div className="supp-ingredients">
                              <h4>Supplements:</h4>
                              <ul className="supplement-list">
                                {item.supplements.map((supp, index) => {
                                  const suppPrice = garniture.find(g => g.name === supp)?.price || 0;
                                  return (
                                    <li key={index}>
                                      <span>{supp}</span>
                                      <span>+{suppPrice.toFixed(2)}€</span>
                                    </li>
                                  );
                                })}
                              </ul>
                              <p>Total suppléments: +{supplementsPrice.toFixed(2)}€</p>
                              <p>Total article: {(basePrice + supplementsPrice).toFixed(2)}€</p>
                            </div>
                          )}
                          
                          <div className="quantity-controls">
                            <button
                              className="quantity-btn minus"
                              onClick={() => handleQuantityChange(item, -1)}
                            >
                              -
                            </button>
                            <span className="quantity-value">{item.quantity}</span>
                            <button
                              className="quantity-btn plus"
                              onClick={() => handleQuantityChange(item, 1)}
                            >
                              +
                            </button>
                          </div>
                          <p>Total pour {item.quantity} article{item.quantity > 1 ? 's' : ''}: {itemTotal.toFixed(2)}€</p>
                        </div>
                        <button
                          className="remove-btn"
                          onClick={() => removeFromCart(item.id)}
                        >
                          Supprimer
                        </button>
                      </div>
                    );
                  })}
                
                  <div className="panier-footer">
                    <div className="total">
                      <span>Total</span>
                      <span>{totalPrice.toFixed(2)}€</span>
                    </div>
                    <button className="checkout-btn">Commander</button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Panier;
