import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CartProvider } from '../context/CartContext';
import { Layout } from './Layout';
import { ListPizzas } from './ListPizzas';
import { TypePizza } from './TypePizza';
import { Histoire } from './Histoire';
import { About } from './About';
import { Contact } from './Contact';
import Panier from './Panier.jsx';
import '../styles/app.css';

function App() {
  return (
    <CartProvider>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" component={ListPizzas} />
            <Route path="/pizza/:id" component={TypePizza} />
            <Route path="/histoire" component={Histoire} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
          </Switch>
          <Panier />
        </Layout>
      </Router>
    </CartProvider>
  );
}

export default App;
