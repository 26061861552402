import React, { useState } from 'react';
import { useCart } from '../context/CartContext';
import '../styles/list-pizzas.css';
import { pizzaList } from '../datas/pizzaList';
import { TypePizza } from './TypePizza';
import { FaInfoCircle } from 'react-icons/fa';

function ListPizzas() {
  const { addItem } = useCart();
  const [selectedSize, setSelectedSize] = useState('classique');
  const [showAllergenes, setShowAllergenes] = useState(false);

  const handleAddToCart = (item, quantity) => {
    addItem(item, quantity);
  };

  return (
    <div className="list-pizzas">
      <div className="size-filter">
        <select 
          value={selectedSize}
          onChange={(e) => setSelectedSize(e.target.value)}
          className="size-select"
        >
          <option value="classique">Classique</option>
          <option value="large">Large</option>
        </select>
        
        <button 
          className="allergenes-btn"
          onClick={() => setShowAllergenes(true)}
          aria-label="Voir les allergènes"
        >
          <FaInfoCircle /> (allergènes)
        </button>
      </div>

      {showAllergenes && (
        <div className="allergenes-modal" onClick={() => setShowAllergenes(false)}>
          <div className="allergenes-content" onClick={(e) => e.stopPropagation()}>
            <h3>Liste des allergènes</h3>
            <div className="allergenes-list">
              <p><strong>Présents dans nos produits ou pouvant être manipulés dans notre cuisine :</strong></p>
              <ol>
                <li><strong>Gluten</strong> - Présent dans les céréales comme le blé, l'orge, le seigle, l'avoine, et leurs dérivés (pâtes, pain, pizza).</li>
                <li><strong>Lait</strong> - Contient du lactose, présent dans les fromages (mozzarella, chèvre, parmesan, etc.) et les produits laitiers.</li>
                <li><strong>Œufs</strong> - Utilisés dans certaines garnitures, sauces ou desserts maison.</li>
                <li><strong>Poisson</strong> - Présent dans les garnitures comme le thon, les anchois ou certaines sauces.</li>
                <li><strong>Crustacés</strong> - Présents dans des fruits de mer comme les crevettes ou les garnitures aux fruits de mer.</li>
                <li><strong>Mollusques</strong> - Utilisés dans des plats ou pizzas aux calamars, moules ou autres fruits de mer.</li>
                <li><strong>Arachides</strong> - Rare, mais peut être utilisé dans des sauces ou desserts spécifiques.</li>
                <li><strong>Soja</strong> - Peut être présent dans des sauces industrielles ou substituts végétariens.</li>
                <li><strong>Fruits à coque</strong> - Inclut amandes, noisettes, noix, noix de cajou, noix de pécan, pignons de pin (souvent dans le pesto ou les desserts).</li>
                <li><strong>Graines de sésame</strong> - Utilisées en décoration ou dans certains plats et pains.</li>
                <li><strong>Moutarde</strong> - Présente dans certaines sauces ou vinaigrettes utilisées comme base ou accompagnement.</li>
                <li><strong>Céleri</strong> - Parfois utilisé dans les sauces tomate préparées ou les bouillons.</li>
                <li><strong>Sulfites</strong> - Utilisés comme conservateurs dans les charcuteries, les sauces ou les vins.</li>
                <li><strong>Lupin</strong> - Rare, mais parfois utilisé dans des farines ou produits de boulangerie.</li>
              </ol>
              <p className="important-note">
                <strong>Important :</strong> Nous faisons tout notre possible pour éviter la contamination croisée, mais certains de ces allergènes peuvent être manipulés dans notre cuisine. Si vous avez des allergies ou des intolérances spécifiques, veuillez nous en informer avant de passer commande.
              </p>
              <button 
                className="btn bleu close-btn"
                onClick={() => setShowAllergenes(false)}
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}

      {pizzaList.map((pizza) => (
        <TypePizza 
          key={pizza.id} 
          pizza={pizza}
          selectedSize={selectedSize}
          onAddToCart={handleAddToCart}
        />
      ))}
    </div>
  );
}

export { ListPizzas };
