import React from 'react';
import '../styles/contact.css';

const Contact = () => {
  return (
    <div className="contact-container">
      <h1 className="contact-title">Contactez-nous</h1>
      
      <div className="contact-content">
        <section className="contact-section">
          <h2>Informations de contact</h2>
          <p>
            <strong>Téléphone:</strong> +32 123 45 67 89<br/>
            <strong>Email:</strong> contact@pizzeria.com<br/>
            <strong>Adresse:</strong> Rue de la Pizza, 1000 Bruxelles
          </p>
        </section>

        <section className="contact-section">
          <h2>Formulaire de contact</h2>
          <form className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Nom:</label>
              <input type="text" id="name" name="name" required />
            </div>
            
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" required />
            </div>
            
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" rows="5" required></textarea>
            </div>
            
            <button type="submit" className="submit-btn">Envoyer</button>
          </form>
        </section>
      </div>
    </div>
  );
};

export { Contact };
