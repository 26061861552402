import React, { useState } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import Panier from './Panier.jsx';
import '../styles/layout.css';

export const Layout = ({ children }) => {
  const [isCartOpen, setIsCartOpen] = useState(false);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  return (
    <div className="app-container">
      <Header toggleCart={toggleCart} />
      
      <div className="main-content">
        {children}
        
        <Panier 
          isOpen={isCartOpen}
          toggleCart={toggleCart}
        />
      </div>
      
      <Footer />
    </div>
  );
};
